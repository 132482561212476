export default class User {
  uuid = null;
  username = null;

  verified = false;
  exclusiveTrading = false;

  mfaPreference = 'EMAIL';
  mfaOptions = [];

  expiration = null;

  constructor(tokenData) {
    this.uuid = tokenData.uuid;
    this.username = tokenData.sub;
    this.hashedEmail = tokenData.email;
    this.verified = tokenData.verified || false;
    this.exclusiveTrading = tokenData.exclusiveTrading || false;
    this.mfaPreference = tokenData.mfaType || 'EMAIL';
    this.mfaOptions = tokenData.mfaOptions || [];
    this.expiration = tokenData.exp;
  }

  get email() {
    try {
      return decodeURIComponent(escape(window.atob(this.hashedEmail || '')));
    } catch (e) {
      return ''
    }
  }
}
