import Token from '@/models/token';
import $store from '@/store/';
import { getLegacyTokenFromCookie, getTokenFromCookie } from '@/services/cookies';

const DEFAULT_UNAUTHENTICATED_ROUTE = '/login';
const DEFAULT_AUTHENTICATED_ROUTE = '/verify';
const DEFAULT_VERIFIED_ROUTE = '/redirect';

/*
  mustBeAuthenticated: token must be checked and user must be authenticated
  mustBeVerified: token must be checked, user must be authenticated and verified
  bypass: do not run any guard (for instances where we want to handle our own redirects)
*/

export const defaultCheck = (to, from, next) => {
  let { mustBeVerified, mustBeAuthenticated, bypass, redirect } = to.meta;
  let isAuthenticated = $store.getters.isAuthenticated;
  let isVerified = $store.getters.isVerified;
  let redirectUrl = (redirect) ? `?redirectUrl=${encodeURIComponent(to.fullPath)}` : ''

  if(bypass)
    return next()

  if(mustBeAuthenticated && !isAuthenticated) {
    return next(DEFAULT_UNAUTHENTICATED_ROUTE + redirectUrl);
  }

  if(!mustBeAuthenticated && isAuthenticated) {
    return next(DEFAULT_AUTHENTICATED_ROUTE);
  }

  if(mustBeVerified && !isVerified) {
    return next(DEFAULT_AUTHENTICATED_ROUTE);
  }

  if(!mustBeVerified && isVerified) {
    return next(DEFAULT_VERIFIED_ROUTE);
  }

  return next();
}

export const checkAuthentication = (to, from, next) => {
  let { mustBeVerified, mustBeAuthenticated } = to.meta;
  if(mustBeVerified || mustBeAuthenticated) {
    $store.dispatch('check');
  }

  return next();
}

export const loadStoreFromCookie = (to, from, next) => {
  let legacyToken = getLegacyTokenFromCookie();
  let token = getTokenFromCookie();
  let urlToken = to.query?.token

  if(token || legacyToken || urlToken)
    $store.dispatch('loginFromToken', new Token(token || urlToken || legacyToken))

  return next();
}
