export default class Token {
  jwt = null;

  constructor(str) {
    this.jwt = str;
  }

  get expiration() {
    return this.data.exp;
  }

  get data() {
    let base64Url = this.jwt.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let json = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(json);
  }
}
