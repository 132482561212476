<!--eslint-disable-->
<template>
  <div class="pt-7 mt-7 border-t border-brand-gray">
    <div v-if="hasFuturesLink" class="font-poppins text-lg text-brand-gray font-semibold mb-7">
      Want to login to your Futures Account?
      <a href="https://www.dormanaccounts.com/eapp/user/login" class="text-brand-secondary font-normal underline hover:no-underline" tabindex="10">Login Here</a>
    </div>
    <p class="text-xs text-brand-gray font-sans mt-0">©{{ year }}, Tradier Inc., and subsidiaries. All rights reserved. Securities products are offered through Tradier Brokerage Inc. Member <a href="https://www.finra.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">FINRA</a>/<a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">SIPC</a> - Not FDIC Insured - No Bank Guarantee - May Lose Value. Commodity interest products, including futures and forex are offered by Tradier Futures (DBA of Lazzara Consulting Inc). Member <a href="https://www.nfa.futures.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">NFA</a>/<a href="https://www.cftc.gov/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">CFTC</a>. Please visit <a href="https://tradier.com/legal" target="_blank" class="text-brand-secondary hover:underline">Tradier Disclosures/Policies</a> for a full list of policies and disclosures. Version: {{ version }} ({{ environment }})</p>
  </div>
</template>

<script>
import pkg from '../../package';

export default {
  name: 'AppDisclaimer',
  props: {
    hasFuturesLink: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      version: pkg.version,
      environment: process.env.NODE_ENV,
      year: new Date().getFullYear()
    }
  }
}
</script>
