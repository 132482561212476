import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from './user';
import PreferencesModule from './preferences'
import NotificationsModule from './notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    }
  },
  actions: {
  },
  modules: {
    user: UserModule,
    preferences: PreferencesModule,
    notifications: NotificationsModule
  }
})
