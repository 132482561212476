export default {
  state: () => ({
    notification: null
  }),

  mutations: {
    setNotification(state, { type, message }) {
      state.notification = { type, message };
    },
    clearNotification(state) {
      state.notification = null;
    }
  },

  actions: {
    addNotification({ commit }, { type, message }) {
      commit('setNotification', { type, message });
    },
    addSuccess({ dispatch }, message) {
      dispatch('addNotification', { type: 'success', message });
    },
    addError({ dispatch }, message) {
      dispatch('addNotification', { type: 'error', message });
    }
  }
};
