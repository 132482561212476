import { getTokenFromCookie } from "@/services/cookies";
import Vue from "vue";

const axiosConfig = {
  headers: { Authorization: `Bearer ${getTokenFromCookie()}` },
};

export const updateMfa = async ({ mfaType, mfaEnabled, phoneNumber }) => {
  phoneNumber = phoneNumber?.replace(/[^0-9]/g, "");

  return Vue.axios.put(
    "/api/preferences",
    {
      mfaType,
      mfaEnabled,
      phoneNumber,
    },
    axiosConfig
  );
};

export const updatePassword = async ({ password, currentPassword }) => {
  return Vue.axios.put(
    "/api/account/change-password",
    {
      password,
      currentPassword,
    },
    axiosConfig
  );
};

export const getCurrentMfa = async () => {
  return Vue.axios.get("/api/preferences", axiosConfig);
};

export const revokeSessions = async () => {
  return Vue.axios.put("/api/account/logout-sessions", {}, axiosConfig);
};
