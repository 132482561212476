import Vue from 'vue';
import { clearTokenCookie } from '@/services/cookies';

export function authorize(token, params) {
    return Vue.axios.get('/api/oauth/authorize', {
        headers: {
            'Authorization': `Bearer ${token || ''}`
        },
        params: params
    }).then((response) => {
        // pre-authorized apps return 201 status code
        if (response?.status == 201) {
            clearTokenCookie();
            window.location.href = response.data.redirectTo;
        }

        return response.data;
    }).catch((e) => {
      if(e.response?.data?.message)
        throw new Error(e.response.data.message)

      throw new Error('An error occurred while sending your request.')
    });
}

export function approve(token, params) {
    return Vue.axios.post('/api/oauth/authorize', params, {
        headers: {
            'Authorization': `Bearer ${token || ''}`
        }
    }).then((response) => {
        return response.data.redirectTo;
    });
}

export function reject(token, params) {
    return Vue.axios.delete('/api/oauth/authorize', {
        headers: {
            'Authorization': `Bearer ${token || ''}`
        },
        params
    }).then((response) => {
        return response.data.redirectTo;
    });
}
