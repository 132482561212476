<template>
  <div v-if="isLoading" data-automation="loader" class="fixed inset-0 bg-brand-lightGray/75 flex justify-center items-center z-50">
    <LoaderIcon data-automation="loader-icon" class="animate-spin" />
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderIcon from '@/components/Icons/LoaderIcon.vue';

export default {
  name: 'AppLoader',
  components: {
    LoaderIcon
  },
  computed: {
    ...mapState({
      isLoading: state => state.loading
    })
  }
}
</script>
