import Vue from 'vue'
import VueRouter from 'vue-router'

import { loadStoreFromCookie, defaultCheck, checkAuthentication } from '@/middleware/authentication'

import DefaultLayout from '../layouts/default'
import LoginLayout from '../layouts/login'

Vue.use(VueRouter)

/*
  mustBeAuthenticated: token must be checked and user must be authenti
  mustBeVerified: token must be checked, user must be authenticated and ver
*/

Vue.router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        path: '/login'
      }
    },
    {
      path: '/login',
      component: LoginLayout,
      children: [
        {
          name: 'login',
          path: '',
          component: () => import('../pages/login.vue'),
          meta: {
            mustBeAuthenticated: false,
            mustBeVerified: false
          }
        }
      ]
    },
    {
      path: '/logout',
      component: LoginLayout,
      children: [
        {
          name: 'logout',
          path: '',
          component: () => import('../pages/logout.vue'),
          meta: {
            bypass: true
          }
        }
      ]
    },
    {
      path: '/verify',
      component: LoginLayout,
      children: [
        {
          name: 'verify',
          path: '',
          component: () => import('../pages/verify.vue'),
          meta: {
            mustBeAuthenticated: true,
            mustBeVerified: false
          }
        }
      ]
    },
    {
      path: '/redirect',
      component: DefaultLayout,
      children: [
        {
          name: 'redirect',
          path: '',
          component: () => import('../pages/redirect.vue'),
          meta: {
            mustBeAuthenticated: true,
            mustBeVerified: true
          }
        }
      ]
    },
    {
      path: '/preferences',
      component: LoginLayout,
      props: {
        hasAdvertisement: false
      },
      children: [
        {
          name: 'preferences',
          path: '',
          component: () => import('../pages/preferences/index.vue'),
          meta: {
            mustBeAuthenticated: true,
            mustBeVerified: true,
            redirect: true
          }
        }
      ]
    },
    {
      path: '/password-reset',
      component: LoginLayout,
      children: [
        {
          name: 'password-reset',
          path: '',
          component: () => import('../pages/password-reset-start.vue'),
          meta: {
            mustBeAuthenticated: false,
            mustBeVerified: false
          }
        }
      ]
    },
    {
      path: '/password-reset/finish',
      component: LoginLayout,
      children: [
        {
          name: 'password-reset-edit',
          path: '',
          component: () => import('../pages/password-reset-finish.vue'),
          meta: {
            mustBeAuthenticated: false,
            mustBeVerified: false
          }
        }
      ]
    },
    {
      path: '/authorize',
      component: LoginLayout,
      children: [
        {
          name: 'authorize',
          path: '',
          component: () => import('../pages/authorize.vue'),
          meta: {
            mustBeAuthenticated: true,
            mustBeVerified: true,
            redirect: true
          }
        }
      ]
    },
    {
        path: '/',
        component: DefaultLayout,
        children: [{
            path: '*',
            name: '404',
            component: () => import('../pages/errors/404.vue'),
            meta: {
              mustBeAuthenticated: false,
              mustBeVerified: false
            }
        }]
    }
  ]
})

Vue.router.beforeEach(loadStoreFromCookie);
Vue.router.beforeEach(defaultCheck);
Vue.router.beforeEach(checkAuthentication);

export default Vue.router
