import Vue from 'vue';
import store from '../store';

Vue.use({
  install(Vue) {
    Vue.prototype.$success = function(message) {
      store.commit('clearNotification');
      store.dispatch('addSuccess', message);
    }

    Vue.prototype.$error = function(message) {
      store.commit('clearNotification');
      store.dispatch('addError', message);
    }
  }
});
