<template>
  <div class="flex">
    <div class="w-full lg:w-2/3 p-5 lg:pt-10" :class="{ 'lg:max-h-screen lg:overflow-y-auto': hasAdvertisement, 'max-w-[900px] mx-auto': !hasAdvertisement }">
      <div class="flex sm:flex-row justify-between w-full pb-7 sm:pb-0 mb-7 sm:mb-20 border-b sm:border-b-0 border-brand-gray">
        <img src="../../public/images/tradier-logo.svg" class="w-[125px]" alt="Tradier Brokerage">
        <a v-if="isPreferencesPage" :href="tradierWebLink" target="_blank" class="button ml-3 sm:w-64">Back to Tradier</a>
        <span v-else-if="!isAuthorizePage && !isVerifyPage" class="font-poppins sm:text-lg text-brand-gray text-center font-semibold">
          Not a member yet?
          <a href="https://onboarding.tradier.com" class="block sm:inline text-brand-secondary font-normal underline hover:no-underline" tabindex="10">Sign Up Here</a>
        </span>
      </div>
      <div class="w-full mx-auto" :class="{ 'max-w-xl': hasAdvertisement }">
        <AppLoader />
        <router-view />
        <AppDisclaimer :hasFuturesLink="!isPreferencesPage && !isAuthorizePage" />
      </div>
    </div>
    <div v-if="hasAdvertisement" class="hidden lg:flex flex-col justify-center items-center w-1/3 h-screen bg-brand-primary text-white py-20 px-10 overflow-hidden">
      <AppAdvertisements />
    </div>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import AppDisclaimer from '@/components/AppDisclaimer.vue';
import AppAdvertisements from '@/components/Advertisements/AppAdvertisements.vue';

export default {
  name: 'LoginLayout',
  props: {
    hasAdvertisement: {
      type: Boolean,
      default: true
    }
  },
  components: { AppLoader, AppDisclaimer, AppAdvertisements },
  computed: {
    isPreferencesPage() {
      return this.$route.name === 'preferences';
    },
    isVerifyPage() {
      return this.$route.name === 'verify';
    },
    isAuthorizePage() {
      return this.$route.name === 'authorize';
    },
    tradierWebLink() {
      if (process.env.VUE_APP_COOKIE_ENV === 'production') {
        return 'https://dash.tradier.com/dashboard';
      } else {
        return 'https://staging-dash.tradier.com/dashboard';
      }
    }
  }
}
</script>
