import Vue from 'vue'
import VueMixpanel from 'vue-mixpanel'

Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL || '',
  config: {
    debug: false,
    ignore_dnt: process.env.NODE_ENV === 'production'
  }
})
