export const shouldUseCaptcha = () => {
  const hasRedirectUrl = new URLSearchParams(window.location.search).has(
    "redirectUrl"
  );
  const isLogin = ["/login", "/login/"].includes(window.location.pathname);

  return !isLogin || !hasRedirectUrl;
};

export const maybeLoadReCaptchaScript = async () => {
  if (window.grecaptcha) {
    return;
  }

  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.onload = resolve;
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`;
    script.setAttribute("data-automation", "google_recaptcha_script");
    document.body.appendChild(script);

    // Allows for testing without jumping through hoops in the test.
    if (process.env.NODE_ENV === "test") {
      setTimeout(script.onload, 100);
    }
  });
};

export const requestReCaptchaToken = async (action) => {
  return new Promise((resolve) => {
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(
          process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          { action }
        );

        resolve(token);
      } catch (error) {
        resolve(undefined);
      }
    });
  });
};
