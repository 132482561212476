import Cookies from 'js-cookie'

export const TOKEN_KEY = 'tradier_auth_token.local';
export const TOKEN_EXPIRATION = 'tradier_auth_token_expiration.local';

export const LEGACY_TOKEN_KEY = 'tradier_auth__token.local';
export const LEGACY_TOKEN_EXPIRATION = 'tradier_auth_token__expiration.local';

export const COOKIE_OPTIONS = {
  domain: (process.env.VUE_APP_COOKIE_ENV === 'production' || process.env.VUE_APP_COOKIE_ENV === 'staging') ? '.tradier.com' : 'localhost',
  sameSite: true,
  secure: true
}

export function getTokenFromCookie() {
  return Cookies.get(TOKEN_KEY);
}

export function saveTokenToCookie(token, expiration) {
  // console.log('saveTokenToCookie', token, expiration);
  if(!token)
    return;

  Cookies.set(TOKEN_KEY, token, COOKIE_OPTIONS);
  Cookies.set(TOKEN_EXPIRATION, expiration, COOKIE_OPTIONS);

  // save the cookie to the old key as well
  saveLegacyTokenToCookie(token, expiration);
}

export function clearTokenCookie() {
  // console.log('clearTokenCookie');
  Cookies.remove(TOKEN_KEY, COOKIE_OPTIONS);
  Cookies.remove(TOKEN_EXPIRATION, COOKIE_OPTIONS);

  // remove the legacy cookie as well
  Cookies.remove(LEGACY_TOKEN_KEY, COOKIE_OPTIONS);
  Cookies.remove(LEGACY_TOKEN_EXPIRATION, COOKIE_OPTIONS);
}

export function saveLegacyTokenToCookie(token, expiration) {
  // console.log('saveLegacyTokenToCookie', token, expiration);
  if(!token)
    return;

  Cookies.set(LEGACY_TOKEN_KEY, `Bearer ${token}`, COOKIE_OPTIONS);
  Cookies.set(LEGACY_TOKEN_EXPIRATION, expiration, COOKIE_OPTIONS);
}

export function getLegacyTokenFromCookie() {
  const value = Cookies.get(LEGACY_TOKEN_KEY);
  if(value) {
    let token = value.split('Bearer ')[1];
    return token || '';
  }

  return '';
}
