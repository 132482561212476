import Vue from 'vue';
import Token from '@/models/token';
import { requestReCaptchaToken, shouldUseCaptcha } from '@/services/google-recaptcha'

export async function login(username, password, redirectUrl) {
  const data = { username, password, redirectUrl }
  if (shouldUseCaptcha()) {
    data.captchaToken = await requestReCaptchaToken("login");
  }

  return Vue.axios.post('/api/authenticate', data).then((response) => {
    return new Token(response.data.id_token);
  }).catch((e) => {
    if(e.response?.status === 401) {
      throw new Error(e.response.data.message)
    }

    throw new Error('An error occurred while sending your request.')
  });
}

export async function startReset(username, email) {
  const data = { username, email };

  if (shouldUseCaptcha()) {
    data.captchaToken = await requestReCaptchaToken("password_reset_start");
  }

  return Vue.axios.put("/api/account/password-reset/start", data);
}

export function verifyReset(username, email, code) {
  return Vue.axios.put('/api/account/password-reset/verify', { username, email, code }).catch((e) => {
    throw new Error(e.response.data.message)
  });
}

export function updatePassword(key, password) {
  return Vue.axios.put('/api/account/password-reset/finish', { key, password });
}

export function send(token, type) {
  return Vue.axios.post('/api/mfa/send', { type },  {
    headers: {
      'Authorization': `Bearer ${token || ''}`
    }
  });
}

export function verify(token, code) {
  return Vue.axios.post('/api/mfa/verify', { code },  {
    headers: {
      'Authorization': `Bearer ${token || ''}`
    }
  }).then((response) => {
    return new Token(response.data.id_token);
  }).catch((e) => {
    if(e.response?.status === 401)
      throw new Error(e.response.data.message)

    throw new Error('An error occurred while sending your request.')
  });
}


export function check(token) {
  return Vue.axios.get('/api/user/authenticated', {
    headers: {
      'Authorization': `Bearer ${token || ''}`
    }
  });
}

export function redirect(token) {
  return Vue.axios.get('/api/redirect', {
    headers: {
      'Authorization': `Bearer ${token || ''}`
    }
  }).then((response) => {
    return response.data.redirectTo;
  });
}
