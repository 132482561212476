import {
  updateMfa,
  updatePassword,
  getCurrentMfa,
  revokeSessions
} from "@/services/preferences";

export default {
  state: () => ({
    mfaType: undefined,
    mfaPhoneNumber: undefined,
  }),

  mutations: {
    setMfaType(state, type) {
      state.mfaType = type;
    },

    setMfaPhoneNumber(state, phoneNumber) {
      state.mfaPhoneNumber = phoneNumber;
    },
  },

  actions: {
    async setCurrentMfa(context) {
      try {
        const { data } = await getCurrentMfa();
        context.commit("setMfaType", data.mfaType);
        context.commit("setMfaPhoneNumber", data.phoneNumber);
      } catch (error) {
        return;
      }
    },

    async updateMfa(context, { mfaType, mfaEnabled, phoneNumber }) {
      context.commit("setMfaType", mfaType);
      context.commit("setMfaPhoneNumber", phoneNumber);

      try {
        await updateMfa({
          mfaType,
          mfaEnabled,
          phoneNumber,
        });
        this._vm.$success("MFA preference updated!");
      } catch (error) {
        this._vm.$error("Something went wrong please try again later.");
      }
    },

    async updatePreferencePassword(_context, { password, currentPassword }) {
      try {
        await updatePassword({
          password,
          currentPassword,
        });

        this._vm.$success("Password updated!");

        return true;
      } catch (error) {
        let message = "Something went wrong please try again later.";
        if (error.response.status === 401) {
          message = "Your current password is incorrect.";
        }

        this._vm.$error(message);
        return false;
      }
    },

    async revokeSessions() {
      try {
        await revokeSessions();
        this._vm.$success("All sessions logged out.");
      } catch (error) {
        let message = "Something went wrong please try again.";
        this._vm.$error(message);
        return false;
      }
    },
  },

  getters: {},
};
