import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return Promise.resolve(response)
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  //eslint-disable-next-line no-console
  console.error(error);
  return Promise.reject(error);
});

Vue.use(VueAxios, axios)
